import axios from "axios";

export const axiosInstance = () => {
  const defaultOptions = {
    baseURL: "https://restaurantapi.twhmenu.com/api",
    // baseURL: "https://testapi.twhtest.com/api",
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.response.use(
    // If request is successful, return response
    (response) => response,
    // If there's an error
    (error) => {
      // Get status code
      const errorReq = error.response ? error.response : null;

      // Pass error code along with error response
      error = errorReq;

      // Reject the promise with error
      return Promise.reject(error);
    }
  );

  return instance; // Return the axios instance
};
